import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "error-container"
}
const _hoisted_2 = { class: "error-message" }
const _hoisted_3 = { class: "button-container" }
const _hoisted_4 = { class: "float-right" }
const _hoisted_5 = {
  style: {"overflow":"auto","height":"85vh"},
  class: "ml-5 mt-5 mb-5"
}
const _hoisted_6 = {
  style: {"overflow":"auto","height":"85vh"},
  class: "ml-5 mt-5 mb-5"
}
const _hoisted_7 = { class: "float-right" }
const _hoisted_8 = { class: "text-left" }
const _hoisted_9 = { class: "text-left" }
const _hoisted_10 = { class: "text-left" }
const _hoisted_11 = { class: "text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WaitDialog = _resolveComponent("WaitDialog")!
  const _component_m_btn = _resolveComponent("m-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_stepper_item = _resolveComponent("v-stepper-item")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_stepper_header = _resolveComponent("v-stepper-header")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_stepper_window_item = _resolveComponent("v-stepper-window-item")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_pagination = _resolveComponent("v-pagination")!
  const _component_v_data_iterator = _resolveComponent("v-data-iterator")!
  const _component_v_stepper_window = _resolveComponent("v-stepper-window")!
  const _component_v_stepper_actions = _resolveComponent("v-stepper-actions")!
  const _component_v_stepper = _resolveComponent("v-stepper")!

  return (_openBlock(), _createBlock(_component_v_container, { style: {"margin-top":"30px"} }, {
    default: _withCtx(() => [
      _createVNode(_component_WaitDialog, {
        isActive: $setup.isPageLoading,
        title: "Working"
      }, null, 8 /* PROPS */, ["isActive"]),
      ($setup.errorMessage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createTextVNode(_toDisplayString($setup.errorMessage) + " ", 1 /* TEXT */),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_m_btn, {
                  onClick: $setup.dismissError,
                  text: "OK"
                }, null, 8 /* PROPS */, ["onClick"])
              ])
            ])
          ]))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_v_overlay, {
        modelValue: $setup.overlayRatingDetails,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.overlayRatingDetails) = $event)),
        height: "100%",
        width: "100%"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, { class: "ma-5" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _cache[17] || (_cache[17] = _createTextVNode("Premium Details ")),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_m_btn, {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.overlayRatingDetails = false)),
                      icon: "mdi-close"
                    })
                  ])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_container, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.requestResponsePairs, (item, index) => {
                    return (_openBlock(), _createBlock(_component_v_row, { key: index }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { col: "6" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_m_btn, {
                              onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.copyTextToClipboard('ratingDetailsRequest'))),
                              text: "Copy to Clipboard"
                            }),
                            _createElementVNode("pre", _hoisted_5, _toDisplayString(item.request), 1 /* TEXT */),
                            _createCommentVNode(" <pre style=\"overflow: auto; height:85vh; \" class=\"ml-5 mt-5 mb-5\">{{ `${item.request} ${index + 1}` }}</pre> ")
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */),
                        _createVNode(_component_v_col, { col: "6" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_m_btn, {
                              onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.copyTextToClipboard('ratingDetailsResponse'))),
                              text: "Copy to Clipboard"
                            }),
                            _createElementVNode("pre", _hoisted_6, _toDisplayString(item.response), 1 /* TEXT */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"]),
      _createVNode(_component_v_overlay, {
        modelValue: $setup.overlayCoverageSummary,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.overlayCoverageSummary) = $event)),
        height: "100%",
        width: "100%",
        style: {"overlay":"auto"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, { class: "ma-5" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _cache[18] || (_cache[18] = _createTextVNode("Coverage Summary ")),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_m_btn, {
                      onClick: $setup.exportCoverageSummary,
                      icon: "mdi-download-circle",
                      type: "primary"
                    }, null, 8 /* PROPS */, ["onClick"]),
                    _createVNode(_component_m_btn, {
                      onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.overlayCoverageSummary = false)),
                      icon: "mdi-close"
                    })
                  ])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_container, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_data_table, {
                    headers: $setup.coverageHeaders,
                    items: $setup.coverageSummaryItems,
                    class: "elevation-1 coverageSummaryTable"
                  }, {
                    [`item.policyPremium`]: _withCtx(({ item }) => [
                      _createElementVNode("span", {
                        class: _normalizeClass({ 'premiumsNotEqual': (item.pointPremium != item.policyPremium) })
                      }, _toDisplayString($setup.formatCurrency(item.policyPremium)), 3 /* TEXT, CLASS */)
                    ]),
                    [`item.pointPremium`]: _withCtx(({ item }) => [
                      _createElementVNode("span", null, _toDisplayString($setup.formatCurrency(item.pointPremium)), 1 /* TEXT */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["headers", "items"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"]),
      ($setup.coverageTypes)
        ? (_openBlock(), _createBlock(_component_v_stepper, {
            key: 1,
            modelValue: $setup.selectedStep,
            "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (($setup.selectedStep) = $event)),
            style: {"margin":"0 auto"}
          }, {
            default: _withCtx(({ }) => [
              _createVNode(_component_v_stepper_header, { class: "bg-primary" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_stepper_item, {
                    title: "Identify Risks",
                    color: "secondary",
                    class: "text-h6",
                    value: "1"
                  }),
                  _createVNode(_component_v_divider),
                  _createVNode(_component_v_stepper_item, {
                    title: "View Premiums",
                    color: "secondary",
                    class: "text-h6",
                    value: "2"
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_stepper_window, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_overlay, {
                    "model-value": $setup.isLoading,
                    class: "align-center justify-center",
                    contained: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_progress_circular, {
                        color: "primary",
                        size: "64",
                        indeterminate: ""
                      })
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["model-value"]),
                  _createVNode(_component_v_stepper_window_item, { value: "1" }, {
                    default: _withCtx(() => [
                      _cache[19] || (_cache[19] = _createElementVNode("div", { class: "mx-auto" }, [
                        _createTextVNode(" Please select policy criteria "),
                        _createElementVNode("b", null, "OR"),
                        _createTextVNode(" enter specific quotes / policies below."),
                        _createElementVNode("br"),
                        _createTextVNode(" (*) fields are required"),
                        _createElementVNode("br")
                      ], -1 /* HOISTED */)),
                      _createVNode(_component_v_form, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_container, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, { col: "5" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_autocomplete, {
                                        onFocus: $setup.specificQuotesVisibility,
                                        onBlur: $setup.specificQuotesVisibility,
                                        class: "pa-0 ma-0",
                                        clearable: "",
                                        items: $setup.insuranceLines,
                                        "item-title": "description",
                                        "item-value": item => item,
                                        label: "Insurance Line*",
                                        "onUpdate:modelValue": [
                                          $setup.manuallyValidateForm,
                                          _cache[6] || (_cache[6] = ($event: any) => (($setup.insuranceLine) = $event))
                                        ],
                                        variant: "solo-filled",
                                        modelValue: $setup.insuranceLine,
                                        disabled: !!$setup.filtersDisabled
                                      }, null, 8 /* PROPS */, ["onFocus", "onBlur", "items", "item-value", "onUpdate:modelValue", "modelValue", "disabled"]),
                                      _createVNode(_component_v_autocomplete, {
                                        onFocus: $setup.specificQuotesVisibility,
                                        onBlur: $setup.specificQuotesVisibility,
                                        class: "pa-0 ma-0",
                                        clearable: "",
                                        items: $setup.states,
                                        "item-title": "name",
                                        "item-value": item => item,
                                        label: "Risk State*",
                                        variant: "solo-filled",
                                        modelValue: $setup.riskState,
                                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.riskState) = $event)),
                                        disabled: !!$setup.filtersDisabled
                                      }, null, 8 /* PROPS */, ["onFocus", "onBlur", "items", "item-value", "modelValue", "disabled"]),
                                      _createVNode(_component_v_text_field, {
                                        onFocus: $setup.specificQuotesVisibility,
                                        onBlur: $setup.specificQuotesVisibility,
                                        onChange: $setup.manuallyValidateForm,
                                        class: "pa-0 ma-0",
                                        clearable: "",
                                        label: "Effective Date*",
                                        type: "date",
                                        variant: "solo-filled",
                                        modelValue: $setup.effectiveDate,
                                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.effectiveDate) = $event)),
                                        disabled: !!$setup.filtersDisabled
                                      }, null, 8 /* PROPS */, ["onFocus", "onBlur", "onChange", "modelValue", "disabled"]),
                                      _createVNode(_component_v_text_field, {
                                        onFocus: $setup.specificQuotesVisibility,
                                        onBlur: $setup.specificQuotesVisibility,
                                        onChange: $setup.manuallyValidateForm,
                                        class: "pa-0 ma-0",
                                        clearable: "",
                                        label: "End Date",
                                        type: "date",
                                        variant: "solo-filled",
                                        modelValue: $setup.endDate,
                                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.endDate) = $event)),
                                        disabled: !!$setup.filtersDisabled
                                      }, null, 8 /* PROPS */, ["onFocus", "onBlur", "onChange", "modelValue", "disabled"]),
                                      _createVNode(_component_v_autocomplete, {
                                        onFocus: $setup.specificQuotesVisibility,
                                        onBlur: $setup.specificQuotesVisibility,
                                        chips: "",
                                        class: "pa-0 ma-0",
                                        clearable: "",
                                        items: $setup.coverageTypesWithCombinedTitle,
                                        "item-title": "combinedTitle",
                                        "item-value": item => item,
                                        label: "Specific Coverages(s)",
                                        multiple: "",
                                        variant: "solo-filled",
                                        modelValue: $setup.coverageType,
                                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($setup.coverageType) = $event)),
                                        disabled: !!$setup.filtersDisabled
                                      }, null, 8 /* PROPS */, ["onFocus", "onBlur", "items", "item-value", "modelValue", "disabled"]),
                                      _createCommentVNode(" Keeping in case needed later "),
                                      _createCommentVNode(" <v-autocomplete class=\"pa-0 ma-0\" clearable :items=\"rateSources\"\n\t\t\t\t\t\t\t\t\t\t\titem-title=\"displayName\" :item-value=\"item => item\" label=\"Rate Sources*\"\n\t\t\t\t\t\t\t\t\t\t\t@blur=\"manuallyValidateForm\" multiple variant=\"solo-filled\"\n\t\t\t\t\t\t\t\t\t\t\tv-model=\"rateSource\"></v-autocomplete> ")
                                    ]),
                                    _: 1 /* STABLE */
                                  }),
                                  _createVNode(_component_v_col, { cols: "1" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_divider, {
                                        vertical: "",
                                        color: "primary",
                                        class: "pa-0",
                                        style: {"margin-top":"-7px"},
                                        thickness: "5"
                                      })
                                    ]),
                                    _: 1 /* STABLE */
                                  }),
                                  _createVNode(_component_v_col, { col: "5" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_textarea, {
                                        onFocus: $setup.filterVisibility,
                                        onBlur: _cache[11] || (_cache[11] = ($event: any) => {$setup.filterVisibility(); $setup.validatePolicyNumbers();}),
                                        onChange: $setup.manuallyValidateForm,
                                        class: "pa-0 ma-0 resize-none",
                                        clearable: "",
                                        onInput: _cache[12] || (_cache[12] = ($event: any) => ($setup.validatePolicyNumbers())),
                                        label: "Known Quote / Policy Numbers*",
                                        rows: "14",
                                        variant: "solo-filled",
                                        modelValue: $setup.specificQuotes,
                                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (($setup.specificQuotes) = $event)),
                                        disabled: !!$setup.specificQuotesDisabled
                                      }, null, 8 /* PROPS */, ["onFocus", "onChange", "modelValue", "disabled"])
                                    ]),
                                    _: 1 /* STABLE */
                                  })
                                ]),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_v_row, { justify: "center" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, { cols: "3" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_m_btn, {
                                        onClick: $setup.clearFilters,
                                        block: true,
                                        text: "Clear Filters",
                                        color: "primary"
                                      }, null, 8 /* PROPS */, ["onClick"])
                                    ]),
                                    _: 1 /* STABLE */
                                  })
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_stepper_window_item, { value: "2" }, {
                    default: _withCtx(() => [
                      _createCommentVNode(" <v-container class=\"container-padding\"> "),
                      _createVNode(_component_v_divider, {
                        thickness: "3px",
                        opacity: ".25"
                      }),
                      _cache[23] || (_cache[23] = _createElementVNode("div", {
                        class: "text-left my-2 mx-2 header-title",
                        style: {"font-size":"16px"}
                      }, "Search Criteria", -1 /* HOISTED */)),
                      _createVNode(_component_v_divider, {
                        thickness: "3px",
                        opacity: ".25"
                      }),
                      _createVNode(_component_v_row, { class: "pt-4" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "3" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_8, "Insurance Line: " + _toDisplayString($setup.insuranceLine.description), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createVNode(_component_v_col, { cols: "3" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_9, "Risk State: " + _toDisplayString($setup.riskState.name), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createVNode(_component_v_col, { cols: "3" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_10, "Effective Date: " + _toDisplayString($setup.effectiveDateFormatted), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          }),
                          ($setup.endDate)
                            ? (_openBlock(), _createBlock(_component_v_col, {
                                key: 0,
                                cols: "3"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_11, "End Date: " + _toDisplayString($setup.endDateFormatted), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              }))
                            : _createCommentVNode("v-if", true)
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_v_row, { style: {"margin-bottom":"10px"} }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, {
                            cols: "12",
                            style: {"margin-top":"0px","padding-top":"0px"}
                          }, {
                            default: _withCtx(() => [
                              _cache[20] || (_cache[20] = _createElementVNode("div", { class: "text-left" }, "Coverages:", -1 /* HOISTED */)),
                              _createVNode(_component_v_list, {
                                style: {"padding-bottom":"0px","padding-top":"0px"},
                                density: "compact"
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.coverageType, (ct) => {
                                    return (_openBlock(), _createBlock(_component_v_list_item, {
                                      style: {"padding-bottom":"0px","padding-top":"0px","min-height":"20px"},
                                      key: ct.coverageTypeCode
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_list_item_title, { class: "text-left" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(ct.coverageTypeCode) + " [" + _toDisplayString(ct.insuranceLineCode) + "] - " + _toDisplayString(ct.coverageTypeDescription), 1 /* TEXT */)
                                          ]),
                                          _: 2 /* DYNAMIC */
                                        }, 1024 /* DYNAMIC_SLOTS */)
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1024 /* DYNAMIC_SLOTS */))
                                  }), 128 /* KEYED_FRAGMENT */))
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createCommentVNode(" </v-container> "),
                      _createVNode(_component_v_divider, {
                        thickness: "3px",
                        opacity: ".25"
                      }),
                      _createVNode(_component_v_data_iterator, {
                        items: $setup.policies,
                        "items-per-page": $setup.policyItemsPerPage,
                        page: $setup.policyCurrentPage
                      }, {
                        header: _withCtx(() => [
                          _createVNode(_component_v_row, { color: "primary" }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.policyTableHeader, (header, index) => {
                                return (_openBlock(), _createBlock(_component_v_col, {
                                  key: index,
                                  class: _normalizeClass(header.title === 'Point' ? 'header-title my-2 point-header' : 'header-title my-2')
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(header.title), 1 /* TEXT */)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class"]))
                              }), 128 /* KEYED_FRAGMENT */))
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createVNode(_component_v_divider, {
                            thickness: "3px",
                            opacity: ".25"
                          })
                        ]),
                        default: _withCtx(({ items }) => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items, (item, i) => {
                            return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                              _createVNode(_component_v_row, {
                                class: "d-flex py-2",
                                align: "center"
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.policyTableHeader, (header, index) => {
                                    return (_openBlock(), _createBlock(_component_v_col, {
                                      key: index,
                                      class: _normalizeClass([header.align])
                                    }, {
                                      default: _withCtx(() => [
                                        (item.raw[header.key] === undefined)
                                          ? (_openBlock(), _createBlock(_component_v_progress_linear, {
                                              key: 0,
                                              color: "primary",
                                              height: "6",
                                              indeterminate: "",
                                              rounded: ""
                                            }))
                                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                              (['RsxDraft', 'RsxApproval', 'RsxReleased', 'Cosmos'].includes(header.key))
                                                ? (_openBlock(), _createBlock(_component_v_btn, {
                                                    key: 0,
                                                    text: item.raw[header.key],
                                                    height: "auto",
                                                    class: _normalizeClass($setup.getRatingResultElementClass(item, header.key)),
                                                    variant: "plain",
                                                    style: {"text-align":"start"}
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(item.raw[header.key]) + " ", 1 /* TEXT */),
                                                      _createVNode(_component_v_menu, {
                                                        "offset-y": "",
                                                        activator: "parent",
                                                        "open-on-hover": ""
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_v_list, null, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_v_list_item, {
                                                                link: "",
                                                                onClick: ($event: any) => ($setup.showCoverageSummary(item.raw[header.key + 'DocumentId']))
                                                              }, {
                                                                default: _withCtx(() => [
                                                                  _createVNode(_component_v_list_item_title, null, {
                                                                    default: _withCtx(() => [
                                                                      _createVNode(_component_v_icon, { icon: "mdi-list-status" }),
                                                                      _cache[21] || (_cache[21] = _createTextVNode(" Coverage Summary"))
                                                                    ]),
                                                                    _: 1 /* STABLE */
                                                                  })
                                                                ]),
                                                                _: 2 /* DYNAMIC */
                                                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
                                                              _createVNode(_component_v_list_item, {
                                                                link: "",
                                                                onClick: ($event: any) => ($setup.downloadTraceWorksheet(item.raw.policyNumber))
                                                              }, {
                                                                default: _withCtx(() => [
                                                                  _createVNode(_component_v_list_item_title, null, {
                                                                    default: _withCtx(() => [
                                                                      _createVNode(_component_v_icon, { icon: "mdi-code-json" }),
                                                                      _cache[22] || (_cache[22] = _createTextVNode(" Trace Worksheet "))
                                                                    ]),
                                                                    _: 1 /* STABLE */
                                                                  })
                                                                ]),
                                                                _: 2 /* DYNAMIC */
                                                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                                                            ]),
                                                            _: 2 /* DYNAMIC */
                                                          }, 1024 /* DYNAMIC_SLOTS */)
                                                        ]),
                                                        _: 2 /* DYNAMIC */
                                                      }, 1024 /* DYNAMIC_SLOTS */)
                                                    ]),
                                                    _: 2 /* DYNAMIC */
                                                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["text", "class"]))
                                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                    _createTextVNode(_toDisplayString(item.raw[header.key]), 1 /* TEXT */)
                                                  ], 64 /* STABLE_FRAGMENT */))
                                            ], 64 /* STABLE_FRAGMENT */))
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class"]))
                                  }), 128 /* KEYED_FRAGMENT */))
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */),
                              _createVNode(_component_v_divider, {
                                class: "m-0",
                                thickness: "1px"
                              })
                            ], 64 /* STABLE_FRAGMENT */))
                          }), 128 /* KEYED_FRAGMENT */))
                        ]),
                        footer: _withCtx(() => [
                          _createVNode(_component_v_row, { class: "justify-center footer-padding" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, { cols: "2" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_select, {
                                    label: "Items per page",
                                    items: [20, 50, 100],
                                    modelValue: $setup.policyItemsPerPage,
                                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (($setup.policyItemsPerPage) = $event))
                                  }, null, 8 /* PROPS */, ["modelValue"])
                                ]),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_v_col, { cols: "6" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_pagination, {
                                    modelValue: $setup.policyCurrentPage,
                                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (($setup.policyCurrentPage) = $event)),
                                    length: $setup.totalMatchingPolicies,
                                    "total-visible": 10
                                  }, null, 8 /* PROPS */, ["modelValue", "length"])
                                ]),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_v_col, { cols: "2" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Total Policies: " + _toDisplayString($setup.totalFoundPolicies), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_v_col, { cols: "2" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_m_btn, {
                                    onClick: $setup.exportSearchResults,
                                    "prepend-icon": "mdi-download-circle",
                                    color: "primary",
                                    text: "Export"
                                  }, null, 8 /* PROPS */, ["onClick"])
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["items", "items-per-page", "page"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_stepper_actions, {
                disabled: $setup.disablePrevNext,
                "onClick:prev": $setup.prevStep,
                "onClick:next": $setup.nextStep,
                "prev-text": $setup.prevButtonName
              }, null, 8 /* PROPS */, ["disabled", "onClick:prev", "onClick:next", "prev-text"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }))
}